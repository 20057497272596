"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ExternalLink, ChevronRight } from "lucide-react";
import { cn } from "@/helpers/utils";
import { Badge } from "@/components/ui/badge";

export default function DrawerItem({
  href,
  text,
  className = "",
  url,
  handleClick,
  badge
}) {
  const pathname = usePathname();
  const isActive = href && (
    pathname === href || 
    (pathname === '/' && href === '/daily')
  );
  
  // Normaliser la casse du badge pour éviter les incohérences
  const badgeText = badge ? badge.toLowerCase() : null;
  
  // Fonction pour rendre le badge
  const renderBadge = (badge) => {
    const badgeText = badge.toLowerCase();
    
    if (badgeText === "updated") {
      return (
        <div className="relative ml-2">
          <div className="relative">
            <span className="absolute inset-0 rounded-md opacity-50 bg-emerald-500 dark:bg-emerald-600 animate-ping"></span>
            <Badge className="relative bg-emerald-500 hover:bg-emerald-600 text-white dark:bg-emerald-600 dark:hover:bg-emerald-500 font-light tracking-wide text-[10px] px-1.5 py-0 h-5">
              {badge}
            </Badge>
          </div>
        </div>
      );
    } else {
      return (
        <div className="relative ml-2">
          <Badge variant="secondary" className="text-[10px] px-1.5 py-0 h-5">
            {badge}
          </Badge>
        </div>
      );
    }
  };
  
  // Classes pour les états actifs et inactifs
  const linkClasses = cn(
    "flex w-full items-center rounded-md px-3 py-2 text-sm font-medium transition-colors",
    isActive 
      ? "text-white font-bold dark:text-white dark:font-bold"
      : "text-white/70 hover:bg-blue-700/50 hover:text-white dark:text-white/80 dark:hover:text-white",
    className
  );
  
  if (url) {
    return (
      <a 
        href={url} 
        target="_blank" 
        rel="noreferrer noopener"
        className={linkClasses}
        onClick={handleClick}
      >
        <div className="flex items-center flex-1">
          <div className="w-[18px]">
            {isActive && (
              <ChevronRight className="h-3.5 w-3.5 text-white/90" />
            )}
          </div>
          <span>{text}</span>
          {badge && renderBadge(badge)}
        </div>
        <ExternalLink className="h-3.5 w-3.5 opacity-70 text-white" />
      </a>
    );
  }
  
  return (
    <Link 
      href={href} 
      className={linkClasses}
      onClick={handleClick}
      prefetch={false}
    >
      <div className="flex items-center flex-1">
        <div className="w-[18px]">
          {isActive && (
            <ChevronRight className="h-3.5 w-3.5 text-white/90" />
          )}
        </div>
        <span>{text}</span>
        {badge && renderBadge(badge)}
      </div>
    </Link>
  );
}
