"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import {Menu, User2, Send, Ticket, Gift, TrendingUp, Calendar } from "lucide-react";
import { ThemeToggle } from "@/components/ui/theme-toggle";
import { cn } from "@/helpers/utils";

// Importer User avec dynamic pour éviter les erreurs d'hydratation
const User = dynamic(() => import("./User"), { 
  ssr: false,
  loading: () => (
    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-primary/10 text-primary">
      <User2 className="w-4 h-4" />
    </div>
  )
});

const navItems = [
  { text: "Verified Coupons", href: "/coupons", smallScreenText: "Coupons", icon: Ticket },
  { text: "Free Courses", href: "/free", smallScreenText: "Free", icon: Gift },
  { text: "Popular Courses", href: "/tops", smallScreenText: "Tops", icon: TrendingUp },
];

// Élément de menu spécifique pour les petits écrans
const smallScreenNavItems = [
  { text: "Daily", href: "/daily", smallScreenText: "Daily", icon: Calendar },
];

// Icône de point d'interrogation personnalisée sans cercle
const QuestionMarkIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2.5" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    className={className}
  >
    <path d="M8.5 9.5a3 3 0 1 1 3.5 3L12 15"/>
    <line x1="12" y1="19" x2="12" y2="19.01"/>
  </svg>
);

export function HeaderView ({ toggleDrawer, isDrawerOpen }) {
  const pathname = usePathname();
  const [isUserMounted, setIsUserMounted] = useState(false);
  const [mounted, setMounted] = useState(false);
  
  // Effet pour marquer le composant User comme monté après un court délai
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsUserMounted(true);
    }, 100); // Petit délai pour s'assurer que le header est rendu
    
    return () => clearTimeout(timer);
  }, []);
  
  // Add mounted state to prevent hydration issues
  useEffect(() => {
    setMounted(true);
  }, []);
  
  // Réinitialiser la position de scroll au chargement de la page
  useEffect(() => {
    // Réinitialiser la position de scroll au chargement
    window.scrollTo(0, 0);
    
    // Optionnellement, empêcher le scroll automatique lors des changements de route
    const handleRouteChange = () => {
      window.scrollTo(0, 0);
    };
    
    // Nettoyer l'effet si nécessaire
    return () => {
      // Aucun nettoyage nécessaire pour cet effet spécifique
    };
  }, [pathname]); // Se déclenche à chaque changement de route

  // Animation du bouton Submit
  const buttonVariants = {
    hover: { 
      scale: 1.05,
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", 
      transition: { duration: 0.2, type: "spring", stiffness: 400 }
    },
    tap: { 
      scale: 0.95,
      transition: { duration: 0.1 }
    }
  };

  // Don't render with theme-specific classes until mounted
  if (!mounted) {
    return (
      <header className="sticky top-0 z-50 w-full border-b shadow-md border-blue-500/50 bg-gradient-to-r from-blue-600 to-blue-700 dark:border-blue-400/50 dark:bg-[#111927] dark:from-transparent dark:to-transparent">
        {/* Simple placeholder header content */}
        <div className="w-full mx-auto">
          <nav className="flex items-center justify-between px-4 h-14">
            <div className="flex items-center">
              <div className="w-8 h-8 mr-2"></div>
            </div>
            <div className="flex items-center gap-1.5"></div>
          </nav>
        </div>
      </header>
    );
  }

  return (
    <header className="sticky top-0 z-50 w-full border-b shadow-md border-blue-500/50 bg-gradient-to-r from-blue-600 to-blue-700 dark:border-blue-400/50 dark:bg-[#111927] dark:from-transparent dark:to-transparent">
      <div className="w-full mx-auto">
        <nav className="flex items-center justify-between px-4 h-14 sm:px-6 lg:px-8 xl:px-12 2xl:px-16">
          {/* Partie gauche: Menu hamburger, logo et navigation mobile */}
          <div className="flex items-center">
            <button
              aria-label={isDrawerOpen ? "Close menu" : "Open menu"}
              className="flex items-center justify-center w-8 h-8 mr-2 text-white transition-colors rounded-full bg-white/10 hover:bg-white/20 dark:bg-blue-400/10 dark:hover:bg-blue-400/20"
              onClick={toggleDrawer}
            >
              <Menu 
                className={cn(
                  "h-5 w-5 transition-transform duration-200",
                  isDrawerOpen && "rotate-90"
                )} 
              />
              <span className="sr-only">{isDrawerOpen ? "Close menu" : "Open menu"}</span>
            </button>
            
            <Link href="/" className="items-center hidden gap-2 sm:flex">
              <span className="font-semibold tracking-wider text-white transition-colors hover:text-blue-100">
                COMIDOC
              </span>
            </Link>
          </div>

          {/* Partie centrale: Navigation desktop */}
          <div className="justify-center flex-1 hidden sm:flex">
            <ul className="flex items-center justify-center space-x-1 md:space-x-2 lg:space-x-4 xl:space-x-6">
              {navItems.map((item) => {
                const Icon = item.icon;
                return (
                  <li key={item.href}>
                    <Link 
                      href={item.href}
                      className={cn(
                        "inline-flex h-9 items-center justify-center rounded-md px-4 py-2 font-semibold",
                        "text-sm sm:text-sm md:text-sm lg:text-base xl:text-base 2xl:text-lg",
                        "whitespace-nowrap transition-all duration-150",
                        pathname === item.href 
                          ? "bg-blue-500 text-white border-2 border-blue-300 shadow-md hover:bg-blue-600 hover:border-blue-400 dark:bg-blue-400/20 dark:border-blue-400/50 dark:hover:bg-blue-400/30 dark:hover:border-blue-400/60"
                          : "text-white/90 bg-blue-700/30 hover:bg-blue-600/50 hover:text-white border-2 border-transparent hover:border-blue-500/40 dark:bg-blue-400/10 dark:text-white dark:hover:bg-blue-400/20 dark:hover:border-blue-400/40"
                      )}
                    >
                      <Icon className="hidden w-4 h-4 mr-2 lg:inline-block" />
                      <span className="hidden md:inline">{item.text}</span>
                      <span className="md:hidden">{item.smallScreenText}</span>
                    </Link>
                  </li>
                );
              })}
              
              {/* Bouton Submit */}
              <li>
                <Link 
                  href="/submit"
                  className={cn(
                    "inline-flex h-9 items-center justify-center rounded-md px-4 py-2 font-semibold",
                    "text-sm sm:text-sm md:text-sm lg:text-base xl:text-base 2xl:text-lg",
                    "whitespace-nowrap transition-all duration-150",
                    pathname === "/submit" 
                      ? "bg-blue-500 text-white border-2 border-blue-300 shadow-md hover:bg-blue-600 hover:border-blue-400 dark:bg-blue-400/20 dark:border-blue-400/50 dark:hover:bg-blue-400/30 dark:hover:border-blue-400/60"
                      : "text-white/90 bg-blue-700/30 hover:bg-blue-600/50 hover:text-white border-2 border-transparent hover:border-blue-500/40 dark:bg-blue-400/10 dark:text-white dark:hover:bg-blue-400/20 dark:hover:border-blue-400/40"
                  )}
                >
                  <Send className="hidden w-4 h-4 mr-2 lg:inline-block" />
                  <span>Submit</span>
                </Link>
              </li>
            </ul>
          </div>

          {/* Navigation mobile - au milieu du header */}
          <div className="flex-shrink-0 sm:hidden">
            <ul className="flex items-center space-x-1.5">
              {[...smallScreenNavItems, ...navItems].map((item) => {
                const Icon = item.icon;
                return (
                  <li key={item.href}>
                    <Link 
                      href={item.href}
                      className={cn(
                        "inline-flex h-8 items-center justify-center rounded-md transition-all duration-150",
                        "px-2 py-1 text-xs font-medium",
                        pathname === item.href 
                          ? "bg-blue-500 text-white border-2 border-blue-300 shadow-md hover:bg-blue-600 hover:border-blue-400 dark:bg-blue-400/20 dark:border-blue-400/50 dark:hover:bg-blue-400/30 dark:hover:border-blue-400/60"
                          : "text-white/90 bg-blue-700/30 hover:bg-blue-600/50 hover:text-white border-2 border-transparent hover:border-blue-500/40 dark:bg-blue-400/10 dark:text-white dark:hover:bg-blue-400/20 dark:hover:border-blue-400/40"
                      )}
                    >
                      <Icon className="w-3 h-3" />
                      <span className="ml-0.5 max-[500px]:hidden">{item.smallScreenText}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Partie droite: Boutons d'action */}
          <div className="flex items-center gap-1.5 sm:gap-3 md:gap-4 lg:gap-5">
            {/* Bouton de bascule de thème */}
            <ThemeToggle className="flex items-center justify-center w-8 h-8 text-white transition-colors rounded-full bg-white/10 hover:bg-white/20 dark:bg-blue-400/10 dark:hover:bg-blue-400/20" />
            
            <Link
              href="/faq"
              aria-label="Help and FAQ"
              className="flex items-center justify-center w-8 h-8 text-white transition-colors rounded-full bg-white/10 hover:bg-white/20 dark:bg-blue-400/10 dark:hover:bg-blue-400/20"
            >
              <QuestionMarkIcon className="w-5 h-5" />
            </Link>
            
            {/* Afficher le composant User seulement après le montage, avec l'icône User non connecté en attendant */}
            {isUserMounted ? (
              <User />
            ) : (
              <Link 
                href="/signup" 
                className="flex items-center justify-center text-white transition-colors rounded-full h-7 w-7 sm:h-8 sm:w-8 bg-white/10 hover:bg-white/20 dark:bg-blue-400/10 dark:hover:bg-blue-400/20"
                aria-label="Sign Up"
              >
                <User2 className="h-3.5 w-3.5 sm:h-4 sm:w-4" />
              </Link>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

