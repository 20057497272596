"use client";
import { useState, useCallback, memo, useEffect } from "react";

import { DrawerView } from "./Drawer/DrawerView";
import { HeaderView } from "./Header/HeaderView";
import { usePathname } from "next/navigation";

// Utilisation de memo pour éviter les re-renders inutiles
const MemoizedDrawer = memo(DrawerView);
const MemoizedHeader = memo(HeaderView);

export function LayoutView({ children }) {
  const [isDrawerOpen, setDrawer] = useState(false);
  const pathname = usePathname();
  
  // Stocker le pathname précédent pour détecter les changements
  const [prevPathname, setPrevPathname] = useState(pathname);

  // Utilisation de useCallback pour éviter de recréer la fonction à chaque render
  const toggleDrawer = useCallback(() => {
    setDrawer(prev => !prev);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawer(false);
  }, []);

  // Fermer automatiquement le drawer lors d'un changement de route
  useEffect(() => {
    if (pathname !== prevPathname && isDrawerOpen) {
      closeDrawer();
    }
    setPrevPathname(pathname);
  }, [pathname, prevPathname, isDrawerOpen, closeDrawer]);

  return (
    <div className="flex min-h-screen flex-col bg-gradient-to-r from-blue-600 to-blue-700 dark:bg-[#111927] dark:from-[#111927] dark:to-[#111927] dark:bg-none">
      <MemoizedHeader
        toggleDrawer={toggleDrawer}
        isDrawerOpen={isDrawerOpen}
      />

      {/* The drawer is now positioned absolutely and will overlay the content */}
      <MemoizedDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={closeDrawer}
      />
      
      <div className="flex-1 pt-2">
        <main className="container mx-auto px-4 sm:px-6 lg:px-8">{children}</main>
      </div>
      
    </div>
  );
}


